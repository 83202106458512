import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Starting.css';
import { Helmet } from 'react-helmet';

function Starting() {

    const navigate = useNavigate();

    const linkNavigate = () => {
        navigate('/CoraLink');
    };

    const billNavigate = () => {
        navigate('/CoraBilling');
    };

    return (
        <div className="startingContainer">
            <Helmet>
                <title>كورا - ابدأ الآن </title>
            </Helmet>
            <h1 className="startingHeader">ابدأ الآن مع كورا</h1>

            <div className="startingContent">

                <div className="start-card">
                    <div className="textContainer" >
                        <h2>رابط الدفع</h2>
                        <p>بدون تعقيدات زِد معدلات التحويل بإستخدام صفحة الدفع </p>
                        <button className="LinkActions" onClick={linkNavigate}>بدء الخدمة</button>
                    </div>
                </div>

                <div className="start-card">
                    <div className="textContainer" >
                        <h2>المدفوعات الدورية</h2>
                        <p> إدارة مدفوعاتك، إدارة الاشتراكات والمزيد، لتتمكن من التركيز على تنمية أعمالك بكل سهولة وفعالية</p>
                        <button className="BillActions" onClick={billNavigate}>بدء الخدمة</button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Starting;