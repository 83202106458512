import { useNavigate } from 'react-router-dom';
import './BillMain.css';
import { Helmet } from 'react-helmet';

function BillMain() {
    const navigate = useNavigate();

    const regNavigate = () => {
        navigate('/Registration');
    };

    const conNavigate = () => {
        navigate('/Contact');
    };

    return (
        <div className="Bill-section-container">

            <Helmet>
                <title>كورا - المدفوعات الدورية </title>
            </Helmet>

            <div className="Bill-section">

                <div className="Bill-sectionTiltle">
                    <label className='beta'>المدفوعات الدورية ●</label>
                    <p className='diBill-main'>سرّع نموك بحلول المدفوعات الدورية</p>
                    <p className='smallBill-desc'>في كورا، نتولى إدارة مدفوعاتك، إدارة الاشتراكات والمزيد، لتتمكن من التركيز على تنمية أعمالك بكل سهولة وفعالية</p>

                    <div className="buttonBill-container">
                        <button className="ctaB-button" onClick={regNavigate}>
                            ابدأ الآن
                            <span className="arrow"></span></button>
                        <button className="conB-button" onClick={conNavigate}>
                            تواصل معنا
                            <span className="arrow"></span></button>
                    </div>
                </div>

                <div className="contentContainer">
                    <h2 className="headline">تخلص من تعقيدات نمو أعمالك</h2>
                    <p className="description">
                        حول الطريقة التي تدير بها الفواتير والاشتراكات. في كورا، نتولى المهام الصعبة نيابة عنك
                    </p>
                </div>

                <div className="icreaseContainer">
                    <label className='beta'>معدلات التحويل ●</label>
                    <h2 className="inHeadline">حقق نموًا في الإيرادات في فترة أقل</h2>
                    <p className="inDescription">
                        يمكنك توفير تجربة اشتراك وشراء بالكامل بلمسة زر. زيادة معدلات التحويل وتحسين تجربة العملاء من خلال صفحة دفع تتوافق مع اللغة والعملة الصحيحة. اجعل عملية التحويل سهلة باستخدام خيارات الدفع المتنوعة
                    </p>
                </div>

                <div className="riskContainer">
                    <label className='beta'>تقليل المخاطر ●</label>
                    <h2 className="riskHeadline">نتولى جميع جوانب التزاماتك من. معالجة المدفوعات، رسوم التاجر، الاحتيال، كلها تُدار من كورا
                    </h2>
                    <p className="riskDescription">
                        أنظمة متقدمة للكشف عن الاحتيال واستعادة وإعادة معالجة تلقائية تقلل من تعرضك للمخاطر
                    </p>
                </div>

                <div className="HowToBillContainer">

                    <h1 className="HowToBillTitle">
                        <span dir="rtl">طريقة استخدام كورا Billing</span>
                        <br />
                        <span className="HowToBillSubtitle"> خطوات بسيطة، لإدارة عمليات الدفع بسهولة وفعالية</span>
                    </h1>

                    <div className="HowToBill-container">

                        <div className="HowToBill-card">
                            <h2>تقارير</h2>
                            <p>إرسال تقارير دورية عن حالة المستخدمين و المدفوعات</p>
                        </div>

                        <div className="HowToBill-card">
                            <h2>متابعة المدفوعات</h2>
                            <p>يتم ارسال رابط الدفع للمستخدمين المضافين تلقائيًا دون تدخل</p>
                        </div>

                        <div className="HowToBill-card">
                            <h2>رابط لوحة التحكم</h2>
                            <p>بعد التسجيل، يتم ارسال رابط للوحة التحكم لادارة المستخدمين</p>
                        </div>

                        <div className="HowToBill-card">
                            <h2>التسجيل</h2>
                            <p>انشاء حساب وتسجيل بيانات التاجر في اقل من دقيقة</p>
                        </div>

                    </div>
                </div>

                <div className="pricingSection">
                    <div className="priceContainer">
                        <h2 className="priceTitle">التسعير</h2>
                        <h3 className="priceHead">المدفوعات والفواتير موحدة في منصة واحدة</h3>
                        <div className="priceRate">
                            <span>5%</span>
                            <p>على كل عملية ناجحة</p>
                        </div>
                    </div>
                    <div className="customPricingContainer">
                        <h2 className="customPricingTitle">تسعير مخصص</h2>
                        <ul className="customPricingFeatures">
                            <li>تسعير يتناسب مع نموذج عملك ومنتجاتك</li>
                        </ul>
                        <button className="conB-button" onClick={conNavigate}>تواصل معنا</button>
                    </div>
                </div>

            </div>
        </div >
    );
}

export default BillMain;