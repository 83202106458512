import React from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { useNavigate } from 'react-router-dom';
import './main.css';
import { Helmet } from 'react-helmet';


function MainSection() {
    const navigate = useNavigate();

    const handNavigate = () => {
        navigate('/contact');
    };

    const startNavigate = () => {
        navigate('/StartingWithCora');
    };


    const [text] = useTypewriter({
        words: ['Cora', 'كورا'],
        loop: true,
        typeSpeed: 120,
        deleteSpeed: 80,
        delaySpeed: 1000
    });

    const handleNavigate = () => {
        window.location.href = 'https://link.cora.sa';
    };

    return (
        <div className="main-section-container">
            <Helmet>
                <title>كورا - حلول المدفوعات </title>
            </Helmet>
            <div className="main-section">

                <label className='beta'>!نسخة بيتا جربها الآن ●</label>
                <h1 className="cora-text">
                    <span>{text}</span>
                    <span className="cursor"><Cursor /></span>
                </h1>

                <p className='dis-main'>حلول المدفوعات الرقمية</p>
                <p className='small-desc'>نوّع طرق الدفع من رابط للدفع الى المدفوعات الدورية، وسّع قنوات التواصل مع عملائك ،
                    ركز على نمو اعمالك</p>
                <div className="button-container">
                    <button className="cta-button" onClick={startNavigate}>
                        ابدأ الآن
                        <span className="arrow"></span></button>
                    <button className="con-button" onClick={handNavigate}>
                        تواصل معنا
                        <span className="arrow"></span></button>
                </div>
            </div>
        </div >
    );
}

export default MainSection;