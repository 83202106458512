import React from 'react';
import './Featuers.css';

function Features() {
    return (
        <div className="Featuers-section">

            <label className='beta'>  مزايا  ●</label>
            <h1 className="main-title">عند استخدام كورا للمدفوعات</h1>
            <p className="main-subtitle">ستتعرف على المزايا المبتكرة التي تحسن حلول المدفوعات بطريقة بسيطة</p>

            <div className="Featuers-container">

                <div className="Featuers-card">
                    <h2>!مشاركة في أي مكان</h2>
                    <p>واحدة <span className="highlight-checkout">Checkout</span> إمكانية الدفع من أي مكان، صفحة </p>
                </div>

                <div className="Featuers-card">
                    <h2> <span className="highlight-Link">Link</span> رابط الدفع</h2>
                    <p>إنشاء روابط دفع لعملياتك بسهولة وسرعة</p>
                </div>

                <div className="Featuers-card">
                    <h2>المدفوعات الدورية</h2>
                    <p>إدارة طرق إضافة المستخدمين وأتمتة عمليات الاشتراك الأسبوعية أو الشهرية</p>
                </div>

                <div className="Featuers-card">
                    <h2>مخصصة <span className="highlight-checkout">Checkout</span> صفحة</h2>
                    <p>يسهل ويسرع عمليات الدفع، مما يحسن من تجربة المستخدم ويزيد من معدلات النمو</p>
                </div>

            </div>
        </div>
    );
};

export default Features;
