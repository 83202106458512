import React from 'react';
import './LinkingPage.css';
import HowToPurc from './HowToPurc';
import LinkFea from './LinkFea';
import { Helmet } from 'react-helmet';


function LinkingPage() {

    const handleNavigate = () => {
        window.location.href = 'https://link.cora.sa';
    };

    return (

        <div className='LinkAll'>
            <Helmet>
                <title>كورا - رابط الدفع </title>
            </Helmet>
            <div className="linkMain-container">

                <video className="video" autoPlay loop muted playsInline>
                    <source src={`${process.env.PUBLIC_URL}/coVid.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                <div className="linkMain-section">
                    <p className='linkDis'>انشئ وشارك رابط الدفع</p>
                    <p className='linkSmall'>بدون تعقيدات زِد معدلات التحويل في صفحة الدفع باستخدام Cora Link</p>
                    <button className="linkButton" onClick={handleNavigate}>شارك رابط الدفع الآن<span class="arrow"></span></button>
                </div>

            </div >
            <div className='howContainer'>
                <div className="HowTo">
                    <div className="HowTo-card">
                        <label className='countNum'>4</label>
                        <h2>اشعارات</h2>
                        <p>إشعارات فورية عند حدوث أي تغير في حالة رابط الدفع لضمان متابعة مستمرة</p>
                    </div>

                    <div className="HowTo-card">
                        <label className='countNum'>3</label>
                        <h2>مشاركة في مختلف المنصات</h2>
                        <p>شارك رابط الدفع المرسل لك على أي منصة دون الحاجة لتوجيه العميل إلى متجر أو موقع إلكتروني</p>
                    </div>

                    <div className="HowTo-card">
                        <label className='countNum'>2</label>
                        <h2>تعبئة بيانات رابط الدفع</h2>
                        <p>أدخل تفاصيل المنتج/الخدمة
                            واحصل على رابط الدفع الخاص بك</p>
                    </div>

                    <div className="HowTo-card">
                        <label className='countNum'>1</label>
                        <h2>التسجيل لبدء الاستخدام</h2>
                        <p>أدخل بياناتك من الاسم ورقم جوال *البيانات لاتظهر عند المستخدمين</p>
                    </div>
                    <p className='linkDi'>:كيف تبدأ</p>
                </div>
            </div>

            <HowToPurc />
            <LinkFea />
        </div>

    );
}

export default LinkingPage;