import React, { useState } from 'react';
import './UserForm.css';
import { Helmet } from 'react-helmet';



const UserForm = () => {
    const initialFormState = {
        currentUserPhone: '',
        username: '',
        email: '',
        phone: '',
        userStatus: '',
        paymentType: '',
        paymentDueDate: '',
        price: '',
        notes: ''
    };

    const [forms, setForms] = useState([initialFormState]);
    const [message, setMessage] = useState('');

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const newForms = [...forms];
        newForms[index][name] = value;
        setForms(newForms);
    };

    const handleAddForm = () => {
        setForms([
            ...forms,
            {
                currentUserPhone: '',
                username: '',
                email: '',
                phone: '',
                userStatus: '',
                paymentType: '',
                paymentDueDate: '',
                price: '',
                notes: ''
            }
        ]);
    };

    const handleDeleteForm = (index) => {
        if (forms.length > 1) {
            const newForms = forms.filter((_, i) => i !== index);
            setForms(newForms);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = 'https://script.google.com/macros/s/AKfycby-9GOkXxH0ISy-0cO81b09BtA7jE3ykXWbvOG_Qe1JltHkxaQ0Lczx9boABh8HEEnqUg/exec'; // Replace with your web app URL

        try {
            for (const form of forms) {
                const params = new URLSearchParams();
                Object.keys(form).forEach(key => {
                    params.append(key, form[key]);
                });

                const response = await fetch(url, {
                    method: 'POST',
                    body: params
                });

                const data = await response.json();
                if (data.result !== "success") {
                    throw new Error('Submission failed');
                }
            }
            setMessage('تمت الاضافة بنجاح، جاري تحديث البيانات');
            setForms([initialFormState]);
        } catch (error) {
            console.error('Error submitting forms:', error);
            setMessage('خطأ في البيانات');
        } finally {
            // Clear the message after 3 seconds
            setTimeout(() => setMessage(''), 6000);
        }
    };


    return (
        <div className="userRe-container">
            <Helmet>
                <title>كورا - لوحة التحكم </title>
            </Helmet>
            <h1>لوحة التحكم</h1>
            <p>إدارة المستخدمين: إضافة، تعديل، وحذف</p>
            <p className='instar'>هاتف التاجر يجب أن يكون نفس الرقم المستخدم عند التسجيل، لذا يُرجى عدم استخدام رقم مختلف. بعد تسجيل البيانات، ستتلقى بريدًا إلكترونيًا يحتوي على قائمة بالأشخاص المسجلين. تستطيع إرسال رابط الدفع مباشرة للعميل باستخدام رقم التاجر، وسيتم إضافته تلقائيًا للمجموعة. سيتم إرسال رابط الدفع للعملاء عند موعد الاستحقاق القادم المسجل، وستتم متابعة المدفوعات تلقائيًا</p>

            <div className="form-controls">
                <button type="button" onClick={handleAddForm} className="addBtn">+</button>
                <button type="button" onClick={() => handleDeleteForm(forms.length - 1)} className="deleteBtn">-</button>
            </div>
            {message && <div className="submission-message">{message}</div>}
            <form className="form-grid" onSubmit={handleSubmit}>
                {forms.map((form, index) => (
                    <div key={index} className="form-instance">
                        <div className="userReform-group full-width">
                            <label htmlFor={`currentUserPhone-${index}`}>هاتف التاجر المسجل</label>
                            <input
                                type="tel"
                                id={`currentUserPhone-${index}`}
                                name="currentUserPhone"
                                placeholder="05"
                                value={form.currentUserPhone}
                                onChange={(e) => handleChange(index, e)}
                                maxLength="10"
                                minLength="10"
                                required
                            />
                        </div>

                        <div className="userReform-group">
                            <label htmlFor={`username-${index}`}>اسم العميل</label>
                            <input
                                type="text"
                                id={`username-${index}`}
                                name="username"
                                value={form.username}
                                onChange={(e) => handleChange(index, e)}
                                maxLength="100"
                                minLength="4"
                                required
                            />
                        </div>
                        <div className="userReform-group">
                            <label htmlFor={`phone-${index}`}>رقم الهاتف</label>
                            <input
                                type="tel"
                                id={`phone-${index}`}
                                name="phone"
                                placeholder="05"
                                value={form.phone}
                                onChange={(e) => handleChange(index, e)}
                                maxLength="10"
                                minLength="10"
                                required
                            />
                        </div>
                        <div className="userReform-group">
                            <label htmlFor={`email-${index}`}>البريد الإلكتروني</label>
                            <input
                                type="email"
                                id={`email-${index}`}
                                name="email"
                                placeholder="@"
                                value={form.email}
                                onChange={(e) => handleChange(index, e)}
                                required
                            />
                        </div>
                        <div className="userReform-group">
                            <label htmlFor={`userStatus-${index}`}>حالة المستخدم</label>
                            <select
                                id={`userStatus-${index}`}
                                name="userStatus"
                                value={form.userStatus}
                                onChange={(e) => handleChange(index, e)}
                                required
                            >
                                <option value="">الحالة</option>
                                <option value="delete">حذف</option>
                                <option value="edit">تعديل</option>
                                <option value="new">جديد</option>
                            </select>
                        </div>
                        <div className="userReform-group">
                            <label htmlFor={`paymentType-${index}`}>نوع الدفع</label>
                            <select
                                id={`paymentType-${index}`}
                                name="paymentType"
                                value={form.paymentType}
                                onChange={(e) => handleChange(index, e)}
                                required
                            >
                                <option value="">اختر نوع الدفع</option>
                                <option value="yearly">سنوي</option>
                                <option value="monthly">شهري</option>
                                <option value="weekly">أسبوعي</option>
                            </select>
                        </div>
                        <div className="userReform-group">
                            <label htmlFor={`paymentDueDate-${index}`}>استحقاق الدفع القادم</label>
                            <input
                                type="date"
                                id={`paymentDueDate-${index}`}
                                name="paymentDueDate"
                                value={form.paymentDueDate}
                                onChange={(e) => handleChange(index, e)}
                                required
                            />
                        </div>
                        <div className="userReform-group full-width">
                            <label htmlFor={`price-${index}`}>مبلغ العملية</label>
                            <input
                                type="number"
                                id={`price-${index}`}
                                name="price"
                                value={form.price}
                                onChange={(e) => handleChange(index, e)}
                                min="50"
                                required
                                className="no-spinners"
                            />
                        </div>
                        <div className="userReform-group full-width">
                            <label htmlFor={`notes-${index}`}>ملاحظات</label>
                            <input
                                id={`notes-${index}`}
                                name="notes"
                                value={form.notes}
                                onChange={(e) => handleChange(index, e)}
                            />
                        </div>
                    </div>
                ))}
                <div className="submit-button-container">
                    <button type="button" onClick={() => window.location.href = 'https://link.cora.sa'} className="linkBtn">انشاء رابط دفع</button>
                    <button type="submit" className="submitBtn">تسجيل</button>
                </div>
            </form>
        </div>
    );
};

export default UserForm;
