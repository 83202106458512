import React, { useState } from 'react';
import './Contact.css'; // Ensure you have a CSS file for styling
import { Helmet } from 'react-helmet';
function Contact() {
    const [formType, setFormType] = useState('contact'); // 'contact' or 'report'
    const [contactFormData, setContactFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: ''
    });
    const [reportFormData, setReportFormData] = useState({
        name: '',
        phone: '',
        productType: '',
        message: ''
    });

    const [contactSuccessMessage, setContactSuccessMessage] = useState('');
    const [reportSuccessMessage, setReportSuccessMessage] = useState('');

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setContactFormData({
            ...contactFormData,
            [name]: value
        });
    };

    const handleReportChange = (e) => {
        const { name, value } = e.target;
        setReportFormData({
            ...reportFormData,
            [name]: value
        });
    };

    const handleSubmit = (e, formType) => {
        e.preventDefault();
        const url = formType === 'contact'
            ? 'https://script.google.com/macros/s/AKfycby8MPO_A_NUel99tiV93cm2khhgTH32SQem59u0eSR6xCQRUZTrOE-zmPyK_vshp_opxA/exec' // Replace with your contact form web app URL
            : 'https://script.google.com/macros/s/AKfycbzh_NmBZK5oqoyNBsGK-OZpLKJLIaZIZiBL8_no26EYylzaENntXBKOApD2v-goHIakMg/exec'; // Replace with your report form web app URL

        const formData = formType === 'contact' ? contactFormData : reportFormData;

        const formBody = new URLSearchParams(formData);

        fetch(url, {
            method: 'POST',
            body: formBody,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                if (response.ok) {
                    if (formType === 'contact') {
                        setContactSuccessMessage('تم الإرسال بنجاح');
                        setContactFormData({
                            name: '',
                            email: '',
                            phone: '',
                            subject: ''
                        });
                        setTimeout(() => setContactSuccessMessage(''), 3000);
                    } else {
                        setReportSuccessMessage('تم الإرسال بنجاح');
                        setReportFormData({
                            name: '',
                            phone: '',
                            productType: '',
                            message: ''
                        });
                        setTimeout(() => setReportSuccessMessage(''), 3000);
                    }
                } else {
                    console.error('Form submission error');
                }
            })
            .catch(error => console.error('Form submission error', error));
    };


    return (

        <div className='contact-page'>

            <Helmet>
                <title>كورا - تواصل معنا </title>
            </Helmet>

            <div className="contact-container">
                {formType === 'contact' && (
                    <div>
                        <h1 className='msg-title'>تواصل معنا</h1>
                        <form onSubmit={(e) => handleSubmit(e, 'contact')}>

                            {contactSuccessMessage && <div className="success-message">{contactSuccessMessage}</div>}

                            <div className="form-group">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={contactFormData.name}
                                    onChange={handleContactChange}
                                    placeholder='الاسم'
                                    maxLength={50}
                                    minLength={4}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={contactFormData.email}
                                    onChange={handleContactChange}
                                    placeholder='البريد الالكتروني'
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={contactFormData.phone}
                                    onChange={handleContactChange}
                                    placeholder='05'
                                    maxLength={10}
                                    minLength={10}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <textarea
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    value={contactFormData.subject}
                                    onChange={handleContactChange}
                                    placeholder='رسالتك'
                                    maxLength={200}
                                    minLength={50}
                                    required
                                />
                            </div>

                            <button className='sun-btn' type="submit">إرسال</button>
                        </form>
                    </div>
                )}

                {formType === 'report' && (
                    <div>
                        <h1 className='msg-title'>اعتراض</h1>
                        <form onSubmit={(e) => handleSubmit(e, 'report')}>

                            {reportSuccessMessage && <div className="success-message">{reportSuccessMessage}</div>}

                            <div className="form-group">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={reportFormData.name}
                                    onChange={handleReportChange}
                                    placeholder='الاسم'
                                    maxLength={50}
                                    minLength={4}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={reportFormData.phone}
                                    onChange={handleReportChange}
                                    placeholder='05'
                                    maxLength={10}
                                    minLength={10}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    id="productType"
                                    name="productType"
                                    value={reportFormData.productType}
                                    onChange={handleReportChange}
                                    placeholder='نوع رابط الدفع: منتج/خدمة'
                                    maxLength={20}
                                    minLength={4}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <textarea
                                    id="message"
                                    name="message"
                                    value={reportFormData.message}
                                    onChange={handleReportChange}
                                    placeholder='سبب الشكوى'
                                    maxLength={200}
                                    minLength={50}
                                    required
                                />
                            </div>

                            <button className='sun-btn' type="submit">إرسال</button>
                        </form>
                    </div>
                )}
            </div>

            <div className='ch-msg'>

                <h1 className='msg-title'>نحن هنا لمساعدتك</h1>
                <p className='msg-sub'>سواءًا كنت تريد التواصل معنا او لديك مشكلة ترغب بحلها، يمكن لفريق كورا مساعدتك</p>
                <div className="form-toggle-buttons">
                    <button className='re-btn' onClick={() => setFormType('report')}>اعتراض</button>
                    <button className='co-btn' onClick={() => setFormType('contact')}>تواصل معنا</button>
                </div>

            </div>

        </div>

    );
};

export default Contact;
