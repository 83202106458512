import React from 'react';
import './WhyCora.css';

function WhyCora() {
    return (
        <div className="whyContainer">

            <label className='beta'>  خصائص  ●</label>
            <h1 className="whyTitle">
                <span dir="rtl">كيف كورا تساعدك تطور اعمالك</span>
                <br />
                <span className="whySubtitle">كورا تقدم لك مجموعة متنوعة من حلول المدفوعات. نتولى إدارة العمليات نيابةً عنك</span>
            </h1>

            <div className="why-container">

                <div className="why-card">
                    <h2>مدفوعات مباشرة او دورية</h2>
                    <p>إنشاء روابط الدفع والمدفوعات الدورية لأتمتة عملية التحصيل</p>
                </div>

                <div className="why-card">
                    <h2>أمان متقدم</h2>
                    <p>نظام أمان متطور لحماية بياناتك ومعاملاتك من الاحتيال</p>
                </div>

                <div className="why-card">
                    <h2>تقارير الاداء</h2>
                    <p>تقارير عن المدفوعات والاشتراكات والعملاء لتحليل وتحسين أداء عملك</p>
                </div>

                <div className="why-card">
                    <h2>طرق دفع متنوعة</h2>
                    <p>بناء علاقات مع مزودين خدمات الدفع والمدفوعات</p>
                </div>

            </div>
        </div>
    )
}
export default WhyCora;