import React, { useState } from 'react';
import './Faq.css'; // Import the CSS file for styling
import { Helmet } from 'react-helmet';

const faqData = [
    {
        question: "كورا Cora",
        answer: "كورا هي منصة مبتكرة لحلول المدفوعات تتيح لك إدارة المدفوعات المتعلقة بالاشتراكات وإنشاء روابط دفع خاصة بك، سهل مشاركتها عبر مختلف المنصات"
    },
    {
        question: "ما هي أنواع الروابط التي يمكن إنشاؤها؟",
        answer: "يمكنك إنشاء أنواع متعددة من روابط الدفع تشمل روابط الدفع الفردي للمدفوعات المحددة، روابط المدفوعات الدورية للاشتراكات المتكررة. تتيح هذه الخدمة مرونة وسهولة في إدارة المدفوعات عبر مختلف السيناريوهات"
    },
    {
        question: "كيف يمكنني عرض روابط المدفوعات؟",
        answer: "يتم ارسال رابط الدفع في كورا عن طريق الرسائل النصية والبريد الإلكتروني انسخ الرابط وشاركة عبر البريد الإلكتروني أو الرسائل النصية أو وسائل التواصل الاجتماعي"
    },
    {
        question: "ما هي خطوات شراء عبر روابط كورا؟",
        answer: "انقر على الرابط، اطلع على التفاصيل، ثم اتبع الخطوات لإتمام الدفع"
    },
    {
        question: "مزايا كورا",
        answer: "توفر كورا واجهة سهلة الاستخدام مع خيارات متنوعة لإنشاء روابط الدفع، وإدارة فعّالة للمدفوعات، وحماية متقدمة للبيانات. بالاضافه الى سهولة المشاركة، الأمان، والتكامل مع أنظمة الدفع المختلفة"
    },
    {
        question: "ماذا لو اشتريت من رابط دفع ولم تعمل؟",
        answer: "في حالة مواجهة مشكلة في الخدمة المقدمة ,نوصي العملاء بالتواصل مباشرة مع فريق كورا قبل ان تتجاوز 24 ساعه من تاريخ الشراء وانتهاء مده الخدمه على البريد الالكتروني لنضمن لك إعادة المبلغ المدفوع "
    },
    {
        question: "قمت بإنشاء رابط دفع, وقررت التراجع .. ماذا أفعل؟",
        answer: "في حاله الالغاء فقط ادخل من الرابط المرسل لك من خلال الرسائل النصية والضغط على زر الغاء الرابط"
    },
    {
        question: "كيف اعرف في حال تم البيع؟",
        answer: "عند البيع يرسل لك اشعار بـ تم البيع من خلال الرسائل النصية، مع بيانات العميل لاكمال ارسال الخدمة له"
    },
    {
        question: "ماذا أفعل إذا واجهت مشكلة في الروابط؟",
        answer: "تواصل معنا عن طريق البريد الالكتروني لحل أي مشكلة تتعلق بالروابط"
    },
    {
        question: "هل تقوم كورا بإرسال تذكيرات لعملاء المدفوعات الدورية وتسهيل عملية الدفع؟",
        answer: "نعم، تقوم كورا بإدارة المدفوعات الدورية بفعالية من خلال إرسال تذكيرات تلقائية للعملاء حول مواعيد استحقاق الدفع. تُرفق هذه التذكيرات بروابط دفع تُمكن العملاء من إتمام المدفوعات بسهولة. هذا يتيح للعملاء التركيز على نمو أعمالهم بينما تتولى كورا مسؤولية إدارة ودعم المدفوعات وضمان إيصالها إلى التاجر"
    },
    {
        question: "كم مده صلاحية روابط الدفع؟",
        answer: "صلاحيه روابط الدفع مستمره لـ 24 ساعه من وقت انشاء الرابط"
    },
    {
        question: "بعد البيع, كيف يتم تحويل المبلغ؟",
        answer: "بعد التاكد من صلاحية عملية البيع يتم ارسال رابط لإضافة بياناتك البنكية لتحويل الرصيد لحسابك (تستغرق عملية تحويل المبلغ 3-5 ايام عمل)"
    },
    {
        question: "هل كورا شركة معتمدة؟",
        answer: "شركة كورا لتقنية المعلومات مسجلة بشكل رسمي في المملكة العربية السعودية , تعمل كـ منصه دفع بين المستخدمين، وهي واحدة من الشركات الريادية المحتضنة لدى البرنامج الوطني لتنمية تقنية المعلومات"
    }
];



function FAQ() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div>

            <Helmet>
                <title>كورا - اسئلة مكرره </title>
            </Helmet>

            <h1 className='faq-title'>اسئلة مكرره</h1>
            <div className="faq-container">
                {faqData.map((item, index) => (
                    <div key={index} className="faq-item">

                        <div className="faq-question-container" onClick={() => toggleAnswer(index)}>

                            <span className="faq-icon">
                                {activeIndex === index ? '✕' : '＋'}
                            </span>

                            <h2 className="faq-question">
                                {item.question}
                            </h2>

                        </div>

                        {activeIndex === index && <p className="faq-answer">{item.answer}</p>}

                    </div>
                ))}
            </div>
        </div>

    );
}

export default FAQ;
