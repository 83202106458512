import React, { useState } from 'react';
import './RegBilling.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const RegBilling = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        userType: 'individual',
        agreeToTerms: false,
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const url = 'https://script.google.com/macros/s/AKfycbw4pAzs2yN8De46I-YVM5h_5ZtWOIh1UGk4QbhQaPSyYr08OztUCDC9_xbj13OE2zzP/exec';
        const formBody = new URLSearchParams({
            ...formData,
            agreeToTerms: formData.agreeToTerms ? 'Yes' : 'No',
        });

        fetch(url, {
            method: 'POST',
            body: formBody,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                if (response.ok) {
                    setSuccessMessage('تم الإرسال بنجاح سيتم ارسال رابط لوحة التحكم قريبا');
                    // Reset the form after successful submission
                    setFormData({
                        name: '',
                        phone: '',
                        email: '',
                        userType: 'individual',
                        agreeToTerms: false,
                    });

                    // After successful form submission, navigate to the new page
                    navigate('/CoraUsersForm');
                } else {
                    console.error('Form submission error: ', response.statusText);
                }
            })
            .catch(error => console.error('Form submission error: ', error))
            .finally(() => {
                setIsLoading(false); // Reset loading state
                setTimeout(() => setSuccessMessage(''), 60000);
            });
    };

    return (
        <div className="registration-container">

            <Helmet>
                <title>كورا - حساب جديد </title>
            </Helmet>

            <h1>حساب جديد</h1>
            <p>ابدأ باستخدام كورا لإدارة المستخدمين والمدفوعات بسهولة وبشكل آلي</p>
            {successMessage && <p className="successMessage">{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">الاسم التجاري</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    maxLength="100"
                    minLength="4"
                    required
                />
                <label htmlFor="phone">رقم الهاتف</label>
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="05"
                    value={formData.phone}
                    onChange={handleChange}
                    maxLength="10"
                    minLength="10"
                    required
                />
                <label htmlFor="email">البريد الإلكتروني</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="@"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="userType">نوع المستخدم</label>
                <select
                    id="userType"
                    name="userType"
                    value={formData.userType}
                    onChange={handleChange}
                    required
                >
                    <option value="individual">فرد</option>
                    <option value="merchant">تاجر</option>
                </select>
                <label htmlFor="agreeToTerms" className="checkbox-label">
                    <input
                        type="checkbox"
                        id="agreeToTerms"
                        name="agreeToTerms"
                        checked={formData.agreeToTerms}
                        onChange={handleChange}
                        required
                    />
                    <a href="/PrivacyPolicy">أوافق على شروط الخدمة وسياسة الخصوصية</a>
                </label>
                <button type="submit" className='regForm' disabled={isLoading}>
                    {isLoading ? 'جاري التسجيل...' : 'تسجيل'}
                </button>
            </form>
        </div>
    );
};

export default RegBilling;
