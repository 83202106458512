import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Tryout.css';


function Tryout() {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        navigate('/StartingWithCora');
    };

    const handleSpeakToSales = () => {
        navigate('/Contact');
    };

    return (
        <div className="TryoutContainer">
            <div className="tryout-content">
                <p>ابدأ الآن مع كورا</p>
                <div className="tryout-buttons">
                    <button className="get-started-button" onClick={handleGetStarted}>ابدأ الآن</button>
                    <button className="speak-to-sales-button" onClick={handleSpeakToSales}>تواصل معنا</button>
                </div>
            </div>
        </div >
    );
}

export default Tryout;