import React from 'react';
import { useNavigate } from 'react-router-dom';
import './sectionTwo.css';

function SectionTwo() {

    const navigate = useNavigate();

    const handleLinkClick = () => {
        navigate('/CoraLink');
    };

    const handleBillClick = () => {
        navigate('/CoraBilling');
    };

    return (
        <div className="section-two-container">
            <h1 className="section-title">
                <label className='beta'>  كورا تقدم ●</label>
                <br />
                <span className="subtitle">حلول مدفوعات مبتكرة لزيادة نمو أعمالك</span>
            </h1>

            <div className="features-container">

                <div className="Billfeature" onClick={handleBillClick}>
                    <div className="text-container">
                        <h2 className="textBill"> ⃪   المدفوعات الالكترونية</h2>
                        <p>مرونة في استقبال المدفوعات  دوريًا من عملائك</p>
                    </div>
                    <div className="icon-container">
                        <img className="billed" src="/sync.png" alt="Share Icon" />
                    </div>
                </div>

                <div className="feature" onClick={handleLinkClick}>
                    <div className="text-container" >
                        <h2 className="textLink"> ⃪   رابط الدفع</h2>
                        <p>انشئ رابط الدفع الخاص بك</p>
                    </div>
                    <div className="icon-container">
                        <img className="linked" src="/link.png" alt="Create Icon" />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SectionTwo;
