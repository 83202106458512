import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import React from 'react';

import MainSection from './components/Main';
import SectionTwo from './components/SectionTwo';
import Features from './components/Featuers';
import FAQ from './components/Faq';
import PrivacyPolicy from './components/PrivacyPolicy';
import Contact from './components/Contact';
import PaymentDetail from './components/PaymentDetail';
import WhyCora from './components/WhyCora';
import Tryout from './components/Tryout';
import LinkingPage from './components/LinkingPage';
import BillMain from './components/BillMain';
import RegBilling from './components/RegBilling';
import UserForm from './components/UserForm';
import Starting from './components/Starting';



const HomePage = () => {

  return (
    <>
      <MainSection />
      <SectionTwo />
      <WhyCora />
      <Features />
      <Tryout />
    </>
  );
};

const Layout = () => {

  const navigate = useNavigate();

  // Example of hidden navigation
  React.useEffect(() => {
    if (window.location.pathname === "/PaymentDetail") {
      navigate("/PaymentDetail");
    }
  }, [navigate]);

  return (
    <div className="App">

      <Helmet>
        <title>كورا - الرئيسية </title>
      </Helmet>

      <div className="header">
        <a href="https://Cora.sa" rel="noopener noreferrer">
          <img src="/coralogo.png" alt="Cora" className="logo" />
        </a>
      </div>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/PaymentDetail" element={<PaymentDetail />} />
        <Route path="/CoraLink" element={<LinkingPage />} />
        <Route path="/CoraBilling" element={<BillMain />} />
        <Route path="/Registration" element={<RegBilling />} />
        <Route path="/CoraUsersForm" element={<UserForm />} />
        <Route path="/StartingWithCora" element={<Starting />} />
      </Routes>

      <div className="footer">
        <hr />
        <div className="footer-content">
          <Link to="/PrivacyPolicy" className="footer-link">سياسة الخصوصية</Link>
          <Link to="/faq" className="footer-link">الأسئلة المتكررة</Link>
          <Link to="/contact" className="footer-link">تواصل معنا</Link>
          <Link to="https://x.com/linkCora" className="footer-link">تابعنا</Link>
        </div>
        <p className='copyright'>Cora 2023 ©</p>
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
