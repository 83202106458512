import React from 'react';
import './LinkFea.css';

function LinkFea() {
    return (
        <div className="linkFeatuersCon">

            <label className='beta'>  مزايا  ●</label>
            <h1 className="linkMainTitle">رابط الدفع الخاص بك</h1>
            <p className="linkMain-subtitle">
                مباشرة إلى عملائك للحصول على المدفوعات. سواء كنت تبيع منتجات أو تقدم خدمات <span className="highlight-link">Link</span> أرسل
            </p>

            <div className="linkFeatuers-container">

                <div className="linkFeatuers-card">
                    <h2>تجربة مستخدم سهله</h2>
                    <p>عملية دفع سهله لتجربة عميل مميزة</p>
                </div>

                <div className="linkFeatuers-card">
                    <h2>طرق متعددة للدفع</h2>
                    <p>خيارات دفع متنوعة تلبي احتياجات جميع المستخدمين، مما يتيح لهم إتمام معاملاتهم بسهولة وسرعة باستخدام الطريقة التي يفضلونها</p>
                </div>

                <div className="linkFeatuers-card">
                    <h2>!مشاركة في أي مكان</h2>
                    <p>واحدة <span className="highlight-checkout">Checkout</span> إمكانية الدفع من أي مكان، صفحة </p>
                </div>

                <div className="linkFeatuers-card">
                    <h2>سهولة الدفع</h2>
                    <p>احصل على الدفع بسهولة بعد التأكد من صلاحية البيع من خلال روابط الدفع. لا توجد متاعب</p>
                </div>

            </div>
        </div>
    );
};

export default LinkFea;
