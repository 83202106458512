import React, { useState } from 'react';
import './PaymentDetail.css'; // Make sure to create this CSS file for styling
import { Helmet } from 'react-helmet';

const PaymentDetail = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        phoneNumber: '',
        id: '',
        bankName: '',
        ibanNumber: ''
    });

    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const scriptURL = 'https://script.google.com/macros/s/AKfycbwpXGZijI5jcnj2eXrwUD4dmLuQIwn95iSbf0b8nKJ8eoxaU5CrkofA08zPtQ39X5Cg/exec'; // Replace with your Google Apps Script web app URL

        const formBody = new URLSearchParams(formData);

        fetch(scriptURL, {
            method: 'POST',
            body: formBody,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                if (response.ok) {
                    setSuccessMessage('تم إرسال التفاصيل بنجاح');
                    setFormData({
                        fullName: '',
                        phoneNumber: '',
                        id: '',
                        bankName: '',
                        ibanNumber: ''
                    });
                    setTimeout(() => setSuccessMessage(''), 3000);
                } else {
                    console.error('Form submission error');
                }
            })
            .catch(error => console.error('Form submission error', error));
    };


    return (
        <div className="payment-detail-container">

            <Helmet>
                <title>كورا - صفحة المدفوعات</title>
            </Helmet>

            <div className="bank-container">
                <h1 className='msg-title'>تفاصيل الدفع</h1>
                {successMessage && <div className="success-message">{successMessage}</div>}
                <form onSubmit={handleSubmit}>

                    <div className="form-group">
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            placeholder="الاسم الكامل"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="phoneNumber">رقم الجوال</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            placeholder="05"
                            maxLength={10}
                            minLength={10}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="id">رقم الهوية/ID</label>
                        <input
                            type="text"
                            id="id"
                            name="id"
                            value={formData.id}
                            onChange={handleChange}
                            placeholder="xxxx-xxxx-xx"
                            maxLength={10}
                            minLength={10}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            id="bankName"
                            name="bankName"
                            value={formData.bankName}
                            onChange={handleChange}
                            placeholder="اسم البنك"
                            maxLength={40}
                            minLength={4}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="ibanNumber">رقم الآيبان</label>
                        <input
                            type="text"
                            id="ibanNumber"
                            name="ibanNumber"
                            value={formData.ibanNumber}
                            onChange={handleChange}
                            placeholder="SA"
                            required
                        />
                    </div>

                    <button className="sun-btn" type="submit">إرسال</button>
                </form>
            </div>

            <div className='ch-msg'>
                <h1 className='msg-title'>صفحة البيانات البنكية</h1>
                <p className='msg-sub'>...ادخل بياناتك المالية لإرسال دفعاتك </p>
                <p className='msg-sub2'>تعبئة البيانات المالية لمرة واحدة فقط، في حال تم ادخالها مسبقا لا حاجة لإعادة تعبئتها مره اخرى، سيتم ارسال الدفعات بعد التحقق من عملية البيع خلال 1 - 3 ايام عمل</p>
            </div>

        </div>
    );
};

export default PaymentDetail;
