import React from 'react';
import './PrivacyPolicy.css';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy-container">

            <Helmet>
                <title>كورا - سياسة الخصوصية </title>
            </Helmet>

            <div className='up-date'>
                <p className='date-pr'>
                    تاريخ السريان: 2024/01/01
                </p>
                <p className='date-pr'>
                    آخر تحديث: 2024/01/01
                </p>
            </div>

            <h1 className='title-pr'>سياسة الخصوصية منصه كورا لتقنية المعلومات</h1>
            <p className='content-pr'>
                مرحبًا بك في منصه كورا, نحن نهتم بخصوصيتك وأمان معلوماتك الشخصية
            </p>
            <p className='content-pr'>
                تحتفظ كورا بالحق في إجراء تغييرات على هذه السياسة في أي وقت وفقاً لتقديرها، وتحديث هذه الصفحة بناء على ذلك، ويتم نشر التعديل وإشعار المستخدمين المسجّلين في كورا  قبل مدة (أسبوع) على الأقل من تاريخ تطبيقه
            </p>
            <p className='content-pr'>
                ويعدّ وصولك إلى موقع كورا الإلكتروني، أو استخدام أي خاصية من خواصهما؛ قبولاً وموافقة منك على الالتزام بسياسة الخصوصية
                فيما يلي شرح لكيفية جمع واستخدام المعلومات الخاصة بك عند استخدام منصه كورا. من فضلك اقرأ هذه السياسة بعناية
            </p>

            <h2 className='sub-pr2'>المعلومات التي نجمعها</h2>
            <p className='content-pr'>
                عند استخدام منصه كورا، قد نقوم بجمع المعلومات التالية:
            </p>

            <p className='content-pr'>
                1- المعلومات الشخصية: قد نجمع معلومات شخصية مثل الاسم، وعنوان البريد الإلكتروني، ورقم الهاتف، ومعلومات الفوترة.
            </p>
            <p className='content-pr'>
                2- بيانات الدفع: إذا قمت بالشراء عبر التطبيق أو الموقع، فسنحتاج إلى بعض المعلومات المالية لتمكين عملية الدفع ومعالجة الطلب تقوم كورا فقط بمعالجة البيانات المالية والمعاملات المتعلقة بالبيانات الشخصية حول حاملي البطاقات/المشترين ومعاملاتهم، والتي قد تشمل الاسم الكامل، وعنوان البريد الإلكتروني، ورقم الهاتف، ورقم بطاقة الائتمان، وتاريخ انتهاء صلاحيتها، وقيمة التحقق الخاصة بالبطاقة، ورقم الهوية الوطنية، ولكن لا تقوم كورا بتخزين رقم بطاقة الائتمان واي بيانات متعلقة بها او معرفتها، حيث ان عملية الخصم الفعلية من حاملي البطاقات وأي معاملات خاصة ببطاقات الائتمان تتم عبر وسيط مالي (طرف ثالث) مرخص من البنك المركزي.
            </p>

            <h2 className='sub-pr'>أسباب جمع البيانات</h2>
            <p className='content-pr'>
                إن الغرض الرئيس من جمع كورا لأي بيانات هو ضمان توفير أفضل الخدمات لمستخدميها. وتشمل الأغراض التي يتم جمع البيانات من أجلها، على سبيل المثال لا الحصر، ما يأتي:
            </p>
            <p className='content-pr'>
                - الرد على أي استفسارات.
            </p>
            <p className='content-pr'>
                - النظر في الشكاوى.
            </p>
            <p className='content-pr'>
                - إدارة التعاملات بين البائع والمشتري وحل المشكلات التي تظهر خلال تقديم الخدمة.
            </p>
            <p className='content-pr'>
                - تيسير عمل حملات البريد الإلكتروني التي يقوم بها قسم المبيعات والتسويق بوفي.
            </p>
            <p className='content-pr'>
                - تحسين خدمة الإعلانات.
            </p>
            <p className='content-pr'>
                - تصميم تجربة المستخدم.
            </p>
            <p className='content-pr'>
                - الوفاء بالتزاماتنا تجاه المستخدمين بموجب اتفاقية شروط وأحكام استخدام كورا.
            </p>
            <p className='content-pr'>
                - الوفاء بالتزاماتنا بموجب الأنظمة والقوانين السعودية.
            </p>
            <p className='content-pr'>
                - إبلاغ المستخدم بالتغييرات التي تطرأ على أحد أنظمتنا الأساسية أو جميعها.
            </p>
            <p className='content-pr'>
                معلومات الجهاز: نجمع معلومات حول جهازك مثل نوع الجهاز، ونظام التشغيل، وعنوان IP، ومتصفح الويب.
                كيف نستخدم المعلومات
            </p>
            <h2 className='sub-pr'>نحن نستخدم المعلومات التي نجمعها لأغراض متنوعة، بما في ذلك:</h2>
            <p className='content-pr'>
                - تسهيل عمليات الشراء.
            </p>
            <p className='content-pr'>
                - تخصيص تجربتك على المنصة وتقديم محتوى مخصص.
            </p>
            <p className='content-pr'>
                - تحسين خدماتنا وأمان منصتنا.
            </p>

            <h2 className='sub-pr2'>مشاركة المعلومات</h2>
            <p className='content-pr'>
                نحن لا نبيع أو نشارك معلوماتك الشخصية مع أطراف ثالثة دون موافقتك الصريحة، إلا إذا كان ذلك ضروريًا لتقديم خدماتك مثل مشاركة بيانات الدفع مع خدمات المعالجة المالية.
            </p>

            <h2 className='sub-pr2'>حقوق الوصول والتحكم</h2>
            <p className='content-pr'>
                لديك حق الوصول إلى معلوماتك الشخصية وتحديثها وحذفها عند الضرورة. يمكنك أيضًا تعيين تفضيلات الخصوصية الخاصة بك في إعدادات التطبيق أو الموقع.
            </p>

            <h2 className='sub-pr2'>أمان المعلومات</h2>
            <p className='content-pr'>
                نحن نعمل بجد لحماية المعلومات الشخصية الخاصة بك من الوصول غير المصرح به واستخدامه أو الكشف عنه. نستخدم إجراءات أمان تقنية وإدارية لضمان سلامة بياناتك.
            </p>

            <h2 className='sub-pr'>مشاركة البيانات لمقدمي الخدمة والأطراف الثالثة:</h2>
            <p className='content-pr'>
                قد تشارك كورا بياناتك مع عدد محدود من مزودي الخدمة بالقدر اللازم لتقديم الخدمات، مثل خدمات التحقق من الهوية واستضافة البنية التحتية وتحليل البيانات ومعالجة خدمات الدفع وخدمة العملاء وإرسال الرسائل الإلكترونية، إذ قد يحتاج مقدمو الخدمة هؤلاء إلى الوصول إلى البيانات الشخصية للتمكّن من أداء مهامهم الضرورية لتقديم كورا لخدماته. كما يجوز لكورا مشاركة البيانات مع أطراف ثالثة من شركائه عندما يكون ذلك ضرورياً لتوفير الخدمات للمستخدمين مثل البنوك، وقد نكشف
                كذلك- عن بياناتك وفق ما تقتضيه الأنظمة للجهات الحكومية والأمنية التي تملك صلاحية طلب مثل تلك البيانات.
            </p>

            <h2 className='sub-pr'>مدة الاحتفاظ بالبيانات</h2>
            <p className='content-pr'>
                تحتفظ كورا ببياناتك طالما كان ذلك ضروريًا على نحو معقول أو مسموح به نظامًا. وسوف نضمن التخلص من البيانات بطريقة آمنة عندما لا يعد هناك حاجة إليها أو بناءً على طلبك. وعند الحاجة إلى إتلاف البيانات، فإن كورا سيتأكد من التخلص منها بطريقة آمنة.
            </p>

            <h2 className='sub-pr'>حقوق المستخدم فيما يتعلق بالبيانات</h2>
            <p className='content-pr'>
                إذا كنت ترغب في الحصول على أي من حقوقك المذكورة أدناه، فإنه ولأغراض أمنية، قد نطلب منك بيانات إضافية لتأكيد هويتك، وذلك قبل الكشف عن بياناتك الشخصية، وسيتوجب علينا الوفاء بجميع تلك الحقوق في غضون فترة زمنية معقولة بما يتماشى مع الأنظمة السارية. وقد لا نتمكن دائمًا من معالجة طلبك بشكل كامل في بعض الحالات، على سبيل المثال:
            </p>
            <p className='content-pr'>
                - إذا كانت المعالجة ستؤثر على السرية التي نلتزم بها تجاه الآخرين.
            </p>
            <p className='content-pr'>
                - إذا كان لوفّي الحق نظامًا في التعامل مع الطلب بطريقة مختلفة.
            </p>
            <p className='content-pr'>
                - إذا كان طلبك يتضمن حذف البيانات المطلوبة للامتثال للمتطلبات النظامية.
            </p>
            <p className='content-pr'>
                وفي جميع الأحوال، سنبذل قصارى جهدنا لتلبية طلبك وفق النظام. وفي حال احتجنا إلى مدة زمنية إضافية بسبب درجة تعقيد الطلب، سوف نبلغك بذلك على الفور.
                بصفتك مالكًا للبيانات، فإنه يحق لك الآتي:
            </p>
            <p className='content-pr'>
                - الحق في الوصول الى البيانات الخاصة بك.
            </p>
            <p className='content-pr'>
                - الحق في تعديل/تحديث البيانات الخاصة بك.
            </p>
            <p className='content-pr'>
                - الحق في طلب اتلاف البيانات الخاصة بك.
            </p>
            <p className='content-pr'>
                - الحق في معرفة كيف نعالج بياناتك ولماذا.
            </p>
            <p className='content-pr'>
                - أي حق آخر منصوص عليه في الأنظمة ذات العلاقة.
            </p>

            <h2 className='sub-pr2'>تغييرات على سياسة الخصوصية</h2>
            <p className='content-pr'>
                قد نقوم بتحديث سياسة الخصوصية بين الحين والآخر لتعكس التغييرات في أنشطتنا وتجارب المستخدمين. ستكون أي تغييرات قائمة فقط بعد نشرها على هذه الصفحة.
            </p>
            <h2 className='sub-pr'>إخلاء المسؤولية</h2>
            <p className='content-pr'>
                يخلي كورا مسؤوليته -إلى أقصى حد تسمح به الأنظمة السارية- في حال كانت البيانات المتاحة في موقعه الالكتروني أو تطبيقه على الهواتف الذكية أو في سياسة الخصوصية هذه غير دقيقة أو غير كاملة أو غير حديثة. كما توافق كمستخدم على تحملك المسؤولية عن متابعة التغييرات في منصاتنا.
                كما أنك تدرك أنه لا يوجد -حتى تاريخه- أي نظام شمولي دخل حيّز النفاذ لحماية البيانات الشخصية في المملكة العربية السعودية. وعلى هذا الأساس، فإن معالجتنا لبياناتك تستند إلى تفسيرنا المعقول للأنظمة التي نعتقد أنها ذات صلة، بما في ذلك نظام البيانات الشخصية الذي لم يدخل حيّز النفاذ ولم تصدر لوائحه بعد، وممارسات الشركاء المماثلين في السوق.
                وتوافق -كذلك- على تعويض كورا وحمايته وإبرائه من المسؤولية، وكذلك الكيانات التابعة له، ومسؤوليه، ومديريه، وموظفيه، من وضد أي وجميع الخسائر، والتبعات، والمصروفات، والتعويضات، والتكاليف، بما في ذلك تكاليف المحاكم وأتعاب المحاماة المعقولة، الناشئة عن أو الناتجة من استخدامك للموقع الالكتروني وتطبيق الهواتف الذكية وأي مخالفة لشروط وأحكام الاستخدام وسياسة الخصوصية. وفي حال تسببت في تعطيل فني للموقع الالكتروني لوفّي أو تطبيقه على الهواتف الذكية أو أي من أنظمته الالكترونية، فأنت توافق على تحمل المسؤولية عن أي وجميع الخسائر، والتبعات، والمصروفات، والتعويضات، والتكاليف، بما في ذلك تكاليف المحاكم وأتعاب المحاماة المعقولة، الناشئة عن أو الناتجة من ذلك التعطيل.
            </p>

            <h2 className='sub-pr2'>الاتصال بنا</h2>
            <p className='content-pr'>
                إذا كان لديك أي أسئلة أو استفسارات حول سياسة الخصوصية، يُرجى التواصل بنا عبر البريد الإلكتروني: Hi@cora.sa
            </p>

            <h1 className='title-pr'>اتفاقية شروط وأحكام استخدام كورا</h1>
            <h2 className='sub-pr2'>أولاً: مقدمة وأحكام تمهيدية</h2>
            <p className='content-pr'>
                1- تحدد هذه الاتفاقية حقوقك والتزاماتك كمستخدم فيما يتعلق بالخدمة المقدمة من كورا، ويعد وصولك إلى الخدمة مشروطًا بقبولك وامتثالك للشروط والأحكام الواردة في هذه الاتفاقية، وفي حال عدم الموافقة على أي من هذه الشروط والأحكام فيجب عليك التوقف فورًا عن الوصول إلى الخدمة أو استخدامها
            </p>
            <p className='content-pr'>
                2- يحق لكورا التعديل على هذه الاتفاقية في أي وقت من الأوقات، ويتم نشر التعديل وإشعار المستخدمين المسجّلين في كورا به قبل مدة (أسبوع) على الأقل من تاريخ تطبيقه
            </p>

            <h2 className='sub-pr2'>ثانياً: الإقرارات</h2>
            <p className='content-pr'>
                1- أن عمرك لا يقل عن (18) عامًا ميلادية، ولديك الأهلية القانونية والشرعية اللازمة للتسجيل في الخدمة واستخدامها، وتنفيذ هذه الشروط والأحكام، والموافقة على منح أية حقوق أو أذون أو تراخيص بموجبها وفق ما تتطلبه الخدمة.
            </p>
            <p className='content-pr'>
                2- أنك اطلعت على سياسة الخصوصية وقبلت بها.
            </p>
            <p className='content-pr'>
                3- أن جميع معلومات التسجيل التي تضعها صحيحة ودقيقة وحديثة وكاملة، وأنك ستحافظ على دقة هذه المعلومات وتُحدّثها على الفور إذا طرأ عليها أي تغيير.
            </p>
            <p className='content-pr'>
                4- أنك ستقوم باستخدام الخدمة بحسن نية ولحسابك الشخصي فقط وليس بالنيابة عن أي شخص أو كيان آخر.
            </p>
            <p className='content-pr'>
                5- أنك لن تخل بأية قوانين أو أنظمة أو قرارات أو تعليمات سارية -أو ما في حكمها- خلال استخدامك للخدمة، وستتحمل المسؤولية منفردًا عن جميع ما يترتب على مخالفتك لتلك القوانين أو الأنظمة أو التعليمات السارية أو ما في حكمها.
            </p>
            <p className='content-pr'>
                6- أنك لن تستخدم الخدمة في أي مشروع احتيالي أو غير قانوني، أو غير مصرّح به، بشكل مباشر أو غير مباشر.
            </p>
            <p className='content-pr'>
                7- أنك لن تستخدم الخدمة بما يؤدي -بأي شكل من الأشكال- إلى تعطيلها أو تعطيل تطبيق كورا أو موقعه الالكتروني.
            </p>
            <p className='content-pr'>
                8- أنك لم تقم بالوصول إلى التطبيق من خلال أي وسائل غير مشروعة أو آلية.
            </p>
            <p className='content-pr'>
                9- أنك تعلم بأن الخدمة لم تُعدّ للاستخدام من قبل أي شخص أو كيان في أي بلد أو ولاية قضائية يكون فيها ذلك الاستخدام مخالفًا للقوانين أو الأنظمة أو اللوائح السارية فيها، وأنك المسؤول الوحيد عن الامتثال للقوانين المحلية في مكان استخدامك.
            </p>
            <p className='content-pr'>
                10- أنك غير خاضع لأية عقوبات وغير مدرج على أي قائمة تتعلق بأطراف محظورة أو مقيدة وأنك غير مملوك أو خاضع لسيطرة أي طرف من تلك الأطراف.
            </p>

            <h2 className='sub-pr2'>ثالثَا: المعاملات المحظورة</h2>
            <p className='content-pr'>
                1- تشمل المعاملات المحظورة -التي لا يجوز استخدام كورا لأجلها- كل ما ينطوي على عنصر غير قانوني أو يهدف لأي غرض غير قانوني، أو يتضمن أي مادة مخالفة للأنظمة أو القوانين السارية أو الآداب العامة، أو يشتمل على ذخيرة أو سلاح ناري، أو يحتوي على برامج مقرصنة أو مقاطع فيديو تنتهك الأعمال المحمية بحقوق الطبع والنشر، أو أي عقاقير غير مشروعة أو مواد مخدرة أو مسكرة، أو أي معاملات أخرى محظورة داخل المملكة العربية السعودية، أو أية تعاملات مع أشخاص أو جهات يُحظر التعامل معهم وفق القوانين والأنظمة السعودية.
            </p>
            <p className='content-pr'>
                2- يوافق كل مستخدم على تعويض كورا عن أي ضرر ناجم بشكل مباشر أو غير مباشر عن معاملة محظورة.
            </p>

            <h2 className='sub-pr2'>رابعًا: الشروط المتعلقة بالتاجر </h2>
            <p className='content-pr'>
                علاوةً على الالتزام بجميع بنود هذه الاتفاقية، يجب على البائع الآتي:
            </p>
            <p className='content-pr'>
                1- التسجيل قبل اضافة المنتج او الخدمة وتحديد البيانات البنكية بعد عملية البيع والتاكد من صلاحية عملية البيع التي يستقبل من خلالها المبالغ المستحقة لمنتجاته، على أن تكون تلك البيانات عائدة إليه وليس لأي شخص أو كيان آخر.
            </p>
            <p className='content-pr'>
                2- الاستمرار في تحمل المسؤولية بشكل منفرد عن المنتج المبيع، ويشمل ذلك المسؤولية عن العيوب والضمانات المتعلقة بالمنتج او الخدمة، وفقًا للأنظمة والقوانين ذات العلاقة بما فيها نظام التجارة الالكترونية.
            </p>
            <p className='content-pr'>
                3- عدم عرض أي محتوى أو منتج ينتهك حقوق الملكية الفكرية.
            </p>
            <p className='content-pr'>
                4- تسليم المنتج او الخدمه او التذكرة طبقًا للمواصفات والتفاصيل المعروضة، قبل مرور 24 ساعه من إتمام عملية الدفع، وفي حالة التأخر عن التسليم فلكورا إعادة قيمة المعاملة إلى المشتري.
            </p>
            <p className='content-pr'>
                5- عدم المطالبة بالمبالغ خلال 60 يومًا من تاريخ الدفع يؤدي إلى فقدان الحق في استردادها.
            </p>
            <p className='content-pr'>
                6- التجاوب مع فريق تسوية المنازعات والتقيّد بقراره في حالة التصعيد لكورا وفقًا للأحكام والإجراءات المنصوص عليها في هذه الاتفاقية.
            </p>
            <p className='content-pr'>
                7-
                تسليم المنتج أو تقديم الخدمة أو تجديد الاشتراك وفقًا للمواصفات والتفاصيل المعروضة، على أن يتم ذلك خلال 24 ساعة من إتمام عملية الدفع. في حالة التأخر، يحق لكورا إعادة قيمة المعاملة إلى المشتري.
            </p>

            <h2 className='sub-pr2'>خامسًا: الشروط المتعلقة بالمستخدمين المشترين</h2>
            <p className='content-pr'>
                علاوةً على الالتزام بجميع بنود هذه الاتفاقية، يجب على المشتري الآتي:
            </p>
            <p className='content-pr'>
                1- تسجيل بيانات المشتري قبل اتمام عمليه الدفع وتعيين وسيلة دفع تدعمها كورا لاستلام المبالغ المحددة لأسعار المنتجات والرسوم المرتبط بها.
            </p>
            <p className='content-pr'>
                2- في حال حدوث مشكله بالخدمة او المنتج المشتراة، الرجاء رفع تذكرة من خلال الموقع الالكتروني بعد عمليه البيع او التواصل معنا على البريد الالكتروني Hi@cora.sa قبل 24ساعه من عملية الشراء.
            </p>
            <p className='content-pr'>
                3- التجاوب مع فريق تسوية المنازعات والتقيّد بقراره في حالة التصعيد لكورا وفقًا للأحكام والإجراءات المنصوص عليها في هذه الاتفاقية.
            </p>

            <h2 className='sub-pr2'>سادسًا: رسوم العمليات</h2>
            <p className='content-pr'>
                كجزء من خدماتنا، سيتم تطبيق الرسوم التالية على كل عملية:
            </p>
            <p className='content-pr'>
                1- رسوم الخدمة والمعالجة: سيتم تطبيق رسوم خدمة ومعالجة بنسبة 5% + 1 ريال سعودي على كل عملية.
            </p>
            <p className='content-pr'>
                2- تحويل المبالغ: سيتم تحويل مبلغ العمليات إذا كانت قيمتها 100 ريال سعودي أو أكثر.
                <br />
                *قد يتم تطبيق رسوم إضافية عند تحويل المبالغ إلى الحساب البنكي.
            </p>
            <h2 className='sub-pr'>طريقة الدفع:</h2>
            <p className='content-pr'>
                •	سيتم خصم الرسوم من إجمالي المبلغ الذي يتم تحويله إلى الحساب البنكي الخاص بك.. قد يتم تطبيق رسوم إضافية لبعض طرق الدفع.
            </p>
            <h2 className='sub-pr'>الاستثناءات:</h2>
            <p className='content-pr'>
                •	قد لا يتم تطبيق الرسوم في حالات معينة مثل العروض الترويجية أو بناءً على اتفاق مسبق مع العملاء.
            </p>
            <p className='content-pr'>
                نحن ملتزمون بتقديم تجربة عميل مميزة، وفي الفترة القادمة سنعمل على تخفيض نسب الرسوم لتعزيز رضى عملائنا وتحسين تجربتهم معنا.
            </p>
            <p className='content-pr'>
                هذه الرسوم ضرورية لتغطية تكاليف معالجة الدفعات وضمان جودة خدماتنا. باستخدامك لخدماتنا، فإنك توافق على تطبيق هذه الرسوم على جميع عملياتك.
            </p>

            <h2 className='sub-pr2'>أحكام ختامية</h2>
            <p className='content-pr'>
                1- تخضع هذه الاتفاقية وكافة الالتزامات الناشئة عنها أو المتعلقة بها لأنظمة المملكة العربية السعودية
            </p>
            <p className='content-pr'>
                2- في حال مخالفتك لأي بند من بنود هذه الاتفاقية، فيحق لكورا تعليق حسابك، أو إنهائه، أو رفض أي وصول أو استخدام منك للخدمة في أي وقت من الأوقات، ولن يكون لك الحق في المطالبة بأي تعويضات، كما يحتفظ كورا بحقه القانوني بمطالبتك بأية تعويضات عن أي ضرر مباشر أو غير مباشر يلحق به، أو بأحد مستخدميه.
            </p>
            <p className='content-pr'>
                3- تقوم كورا بالتواصل معك من خلال الوسائل الالكترونية، كالبريد الإلكتروني أو الرسائل النصية أو إخطارات التطبيقات الداخلية أو المراسلات على الموقع الإلكتروني. وتعدّ كافة الاتفاقيات أو الإخطارات أو الإفصاحات أو المراسلات الأخرى التي نرسلها لك إلكترونياً مستوفية لأي متطلبات قانونية.
            </p>
            <p className='content-pr'>
                4-  يمكنك التواصل معنا من خلال البريد الالكتروني Hi@cora.sa
            </p>

        </div>
    );
}

export default PrivacyPolicy;