import React from 'react';
import './HowToPurc.css';

function HowToPurc() {
    return (
        <div className="how-container">

            <video className="background-video" autoPlay loop muted playsInline>
                <source src={`${process.env.PUBLIC_URL}/coraVidpur.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1 className="how-title">
                <span dir="rtl">من <span className="highlight">Checkout</span> للدفع</span>
                <br />
                <span className="how-subtitle">مكِّن عملاءك من إتمام عملية الدفع في ثوانٍ، أسرع بثلاث مرات مقارنةً بالطريقة التقليدية</span>
            </h1>

        </div>
    )
}
export default HowToPurc;